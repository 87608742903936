<template>
  <div class="modal fade" id="addDiscountModal" tabindex="-1" role="dialog" aria-labelledby="addDiscountModal"
       aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <div class="w-100 mr-2">
            <h1 class="modal-title white">Add Discount ({{ appointment?.reference ?? "No Ref. Found" }})</h1>
            <div class="d-flex flex-row justify-content-between">
              <div class="text-bold-700 text-white">
                <p class="m-0">Total: ${{ parseFloat(this.totalAmount / 100).toFixed(2) }}</p>
              </div>
              <div class="text-bold-700 text-success">
                <p class="m-0"
                   v-bind:style="{cursor: 'pointer'}"
                   v-tooltip="{
                            text:`Card Charges: $`+ parseFloat(this.cardCharge/100).toFixed(2),
                            displayArrow:true,
                            theme:{
                              color:'#000000',
                              placement:'bottom',
                              border: '1px solid green',
                              'background-color':'#FFF1DF'
                            }}"
                >Paid: ${{ parseFloat((this.paidAmount + this.cardCharge) / 100).toFixed(2) }}</p></div>
              <div class="text-bold-700 text-warning">
                <p class="m-0">Remaining: ${{ parseFloat(this.remainingAmount / 100).toFixed(2) }}</p></div>
            </div>
          </div>
          <button type="button" class="close" @click="resetData()" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label>Discount Type</label>
                    <div style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="adjustmentDiscountType" type="checkbox" class="custom-control-input" checked
                                 id="appliedDiscount">
                          <label class="custom-control-label mr-1" for="appliedDiscount"></label>
                        </div>
                        <span class="font-medium-1">
                          {{ adjustmentDiscountType ? "Adjustment Discount" : "Applied Discount" }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="adjustmentDiscountType" class="col-12">
                <div v-if="!adjustableDiscountTimeSlotCount" class="text-danger">No discountable time slot is available
                  to apply discount
                </div>
                <div v-else>
                  <div v-for="index in adjustableDiscountTimeSlotCount" :key="index"
                       class="form-check form-check-inline">
                    <input v-model="adjustableDiscountTimeSlotSelectCounter"
                           :value="index" class="form-check-input"
                           type="checkbox" :id="'adjustmentDiscountTimeSlot-'+index">
                    <label class="form-check-label" :for="'adjustmentDiscountTimeSlot-'+index">30 Mins</label>
                  </div>
                  <div class="mt-1">Discount Amount: ${{ (adjustableDiscountAmount / 100).toFixed(2) }}</div>
                </div>
              </div>
              <div v-else class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label>Discount Amount</label>
                    <input v-model="appliedDiscountAmount" type="text" @keypress="allowOnlyNumbersWithDecimal"
                           class="form-control">
                    <small>Maximum Discountable Amount: ${{ appliedDiscountMaximumAmount.toFixed(2) }}</small>
                  </div>
                  <p class="m-0 text-warning">New Payable Amount: ${{ newRemainingAmount }}</p>
                </div>
              </div>

            </div>

          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button :disabled="isDiscountButtonDisabled" type="button" class="btn btn-primary" @click="addDiscount()">
            <span>Save Changes</span>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

import {
  mapActions,
  mapGetters
} from "vuex";
import GlobalMixin from "../../../../../components/backEnd/mixins/GlobalMixin";

export default {
  name: "AddDiscountModal",
  mixins: [ShowToastMessage, Loader, GlobalMixin],
  props: ['appointment', 'appointmentParams'],

  data() {
    return {
      appointmentUnitPrice: 0,
      appointmentLength: '',
      appointmentReference: '',
      appointmentId: null,
      appointmentUserId: null,

      adjustmentDiscountType: true, // if false, then considered as applied discount.
      adjustableDiscountTimeSlotCount: 0,
      adjustableDiscountTimeSlotSelectCounter: [],
      adjustableDiscountAmount: 0,

      appliedDiscountAmount: 0,
      appliedDiscountMaximumAmount: 0,
      remainingAmount: 0,
      newRemainingAmount: 0,
      totalAmount: 0,
      paidAmount: 0,
      cardCharge: 0,
      appliedDiscountMaximumAmountCent: 0,
      currentGst: 0,
    }
  },
  computed: {
    ...mapGetters({
      availableTechnicians: 'appTechnicians/availableTechnicians',
    }),
    isDiscountButtonDisabled() {
      if (!this.adjustmentDiscountType) {
        return !(this.appointmentReference && this.appointmentId && this.appointmentUserId && this.appliedDiscountAmount && !(this.appliedDiscountAmount >= this.appliedDiscountMaximumAmount));
      }
      return !(this.appointmentReference && this.appointmentId && this.appointmentUserId && this.adjustableDiscountAmount);
    },
  },
  watch: {
    appointment(currentAppointment) {
      if (currentAppointment?.reference) {
        this.appointmentReference = currentAppointment?.reference ?? null;
        this.appointmentId = currentAppointment?.id ?? null;
        this.appointmentUserId = currentAppointment?.customer?.user?.id ?? null;
        this.adjustmentDiscountType = currentAppointment?.status === 'Delivered';

        this.appointmentUnitPrice = currentAppointment?.unit_price; // unit price is in cents.
        this.appointmentLength = currentAppointment?.length;

        let [hours, minutes] = this.appointmentLength.split(":").map(Number);
        this.adjustableDiscountTimeSlotCount = (Math.max(hours - 1, 0) * 2) + (minutes > 0 ? (minutes <= 30 ? 1 : 2) : 0);

        const servicePrice = currentAppointment.appointmentCharges.reduce(function (accumulate, cur) {
          return (cur.type === 'Service Price' ? accumulate + cur.amount : accumulate)
        }, 0);
        this.currentGst = currentAppointment.appointmentCharges.reduce(function (accumulate, cur) {
          return (cur.type === 'GST' ? accumulate + cur.amount : accumulate)
        }, 0);


        const appliedMaxDiscountAmount = (servicePrice - this.appointmentUnitPrice);
        this.totalAmount = currentAppointment.appointmentCharges.reduce(function (accumulate, cur) {
          return ((['Discount', 'Coupon'].includes(cur.type)) ? accumulate - cur.amount : accumulate + cur.amount)
        }, 0);
        this.paidAmount = currentAppointment?.appointmentPayments.reduce(function (accumulate, cur) {
          return (!('payment' in cur) || cur.payment === null) ? 0 : accumulate + cur?.payment?.total_transaction ?? 0
        }, 0);
        this.cardCharge = currentAppointment?.appointmentPayments.reduce(function (accumulate, cur) {

          return (!('payment' in cur) || cur.payment === null) ? 0 :
              cur.payment.type === 'Card' ? accumulate + cur.payment?.cardPayment?.card_surcharge ?? 0 :
                  cur.payment.type === 'Afterpay' ? accumulate + cur.payment?.afterpayPayment?.card_surcharge ?? 0 : 0
        }, 0);
        this.remainingAmount = this.totalAmount - this.paidAmount;
        console.log('appliedMax',appliedMaxDiscountAmount);
        const maxDiscountAmount = Math.min(this.remainingAmount, appliedMaxDiscountAmount);
        if(maxDiscountAmount === 0){
          this.appliedDiscountMaximumAmount =  this.appliedDiscountMaximumAmountCent = 0;
          return;
        }
        /** TODO: make e function total amount, MaxDiscountedAMount, discount, remainingAmount  **/
        const remainingGst = parseInt((this.currentGst * maxDiscountAmount)/ this.totalAmount);
        this.appliedDiscountMaximumAmountCent = parseInt(maxDiscountAmount - remainingGst);
        this.appliedDiscountMaximumAmount = this.appliedDiscountMaximumAmountCent / 100;
      }
    },


    adjustableDiscountTimeSlotSelectCounter(adjustableDiscountTimeSlotSelectCounter) {
      this.adjustableDiscountAmount = this.appliedDiscountMaximumAmountCent * (.5 * adjustableDiscountTimeSlotSelectCounter.length)
    },

    appliedDiscountAmount(currentValue) {
      this.newRemainingAmount = this.calculateFinalAmount(this.totalAmount,currentValue, this.currentGst, this.remainingAmount)
    }

  },
  methods: {
    ...mapActions({
      postAppointmentChargesDiscount: 'appAppointments/postAppointmentChargesDiscount',
      postDiscountStoreList: 'appDiscounts/postDiscountStoreList',

    }),
    calculateFinalAmount(totalAmount, discount, currentGst, previousRemaining) {
      // Ensure the discount is treated as a number
      discount = parseInt(discount * 100) || 0;
      // Convert discount amount to a percentage relative to the total amount
      const currentDiscount = (discount / totalAmount) * 100;

      // Calculate GST on the discounted amount
      let gstAmount = parseInt((currentGst * discount) / totalAmount);

      // Calculate the total discounted amount including GST
      let totalDiscountedAmount = discount + gstAmount;

      // Ensure remaining amount doesn't go below zero
      let finalRemainingAmount = previousRemaining - totalDiscountedAmount;
      console.log(discount,previousRemaining,finalRemainingAmount,totalDiscountedAmount)
      if (finalRemainingAmount < 0) {
        // Adjust discount to fit within previousRemaining
        const maxAllowedDiscount = parseInt(previousRemaining / 1 + (currentGst / totalAmount));
        gstAmount = parseInt((currentGst * maxAllowedDiscount) / totalAmount);
        discount = maxAllowedDiscount;
        totalDiscountedAmount = maxAllowedDiscount + gstAmount;
        finalRemainingAmount = 0; // Set remaining to 0 as it cannot go negative
      }

      return {
        currentAmount: totalAmount,
        providedDiscount: discount.toFixed(2),
        discountedAmount: totalDiscountedAmount.toFixed(2),
        currentDiscount: currentDiscount.toFixed(2),
        gstAmount: gstAmount.toFixed(2),
        finalAmount: finalRemainingAmount.toFixed(2),
      };
    },

    resetData() {
      this.appointmentUnitPrice = 0;
      this.appointmentLength = '';
      this.appointmentReference = '';
      this.appointmentId = null;
      this.appointmentUserId = null;
      this.adjustmentDiscountType = true;
      this.adjustableDiscountTimeSlotCount = 0;
      this.adjustableDiscountTimeSlotSelectCounter = [];
      this.adjustableDiscountAmount = 0;
      this.appliedDiscountAmount = 0;
      this.appliedDiscountMaximumAmount = 0;
    },

    async addDiscount() {
      this.loader(true);
      const response = await this.postAppointmentChargesDiscount({
        id: this.appointmentId,
        data: {
          name: this.adjustmentDiscountType ? "Adjustment Discount" : "Applied Discount",
          amount: this.adjustmentDiscountType ? this.adjustableDiscountAmount : this.appliedDiscountAmount * 100,
        }
      });
      this.loader(false);
      if (response.status === 200) {
        document.querySelector('[data-target="#addDiscountModal"]').click();
        this.createAppointmentDiscountStoreList();
        this.$emit('getAppointmentList', this.appointmentParams);
        this.resetData();
      }
      if (response.message) {
        this.showToastMessage(response);
      }

    },

    createAppointmentDiscountStoreList() {
      /*
        0:Credited Payment,
        1:Applied on Subtotal,
        2:Applied on Shipping Fee,
        3:Coupon on Subtotal,
        4:Coupon on Shipping Fee,
        5:Parking,
        6:Online Appointment
        */

      const dataObj = {
        user_id: this.appointmentUserId,
        reference: this.appointmentReference,
        discounts: [{
          amount: this.adjustmentDiscountType ? this.adjustableDiscountAmount : this.appliedDiscountAmount * 100,
          type: 1,
        }]
      }
      this.postDiscountStoreList(dataObj);
    },

  }

}
</script>
